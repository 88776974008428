exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-creativity-land-index-js": () => import("./../../../src/pages/creativity-land/index.js" /* webpackChunkName: "component---src-pages-creativity-land-index-js" */),
  "component---src-pages-game-garden-index-js": () => import("./../../../src/pages/game-garden/index.js" /* webpackChunkName: "component---src-pages-game-garden-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mind-zone-index-js": () => import("./../../../src/pages/mind-zone/index.js" /* webpackChunkName: "component---src-pages-mind-zone-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0006-toucan-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0006-toucan/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0006-toucan-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0011-owl-sitting-on-a-book-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0011-owl-sitting-on-a-book/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0011-owl-sitting-on-a-book-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0012-chicken-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0012-chicken/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0012-chicken-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0019-stork-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0019-stork/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0019-stork-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0031-chicken-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0031-chicken/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0031-chicken-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0032-eagle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0032-eagle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0032-eagle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0037-dove-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0037-dove/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0037-dove-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0047-fantasy-bird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0047-fantasy-bird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0047-fantasy-bird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0075-fantasy-birds-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0075-fantasy-birds/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0075-fantasy-birds-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0086-cockatoos-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0086-cockatoos/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0086-cockatoos-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0088-fantasy-birds-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0088-fantasy-birds/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0088-fantasy-birds-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0099-parrot-on-a-branch-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0099-parrot-on-a-branch/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0099-parrot-on-a-branch-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0100-bird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0100-bird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0100-bird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0132-parrot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0132-parrot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0132-parrot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0141-peacock-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0141-peacock/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0141-peacock-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0158-chicken-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0158-chicken/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0158-chicken-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0159-fantasy-birds-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0159-fantasy-birds/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0159-fantasy-birds-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0174-perch-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0174-perch/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0174-perch-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0189-winter-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0189-winter-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0189-winter-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0208-snowy-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0208-snowy-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0208-snowy-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0224-two-ducklings-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0224-two-ducklings/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0224-two-ducklings-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0227-simple-pigeon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0227-simple-pigeon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0227-simple-pigeon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0233-ostrich-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0233-ostrich/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0233-ostrich-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0237-winter-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0237-winter-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0237-winter-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0240-simple-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0240-simple-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0240-simple-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0243-little-bluebird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0243-little-bluebird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0243-little-bluebird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0244-winter-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0244-winter-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0244-winter-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0250-chicken-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0250-chicken/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0250-chicken-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0261-parrot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0261-parrot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0261-parrot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0262-peacock-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0262-peacock/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0262-peacock-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0266-toucan-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0266-toucan/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0266-toucan-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0272-perch-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0272-perch/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0272-perch-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0304-toucan-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0304-toucan/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0304-toucan-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0314-peacock-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0314-peacock/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0314-peacock-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0322-cute-parrot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0322-cute-parrot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0322-cute-parrot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0326-bird-in-nest-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0326-bird-in-nest/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0326-bird-in-nest-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0331-bird-in-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0331-bird-in-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0331-bird-in-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0356-heron-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0356-heron/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0356-heron-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0358-flamingos-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0358-flamingos/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0358-flamingos-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0361-bird-on-a-branch-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0361-bird-on-a-branch/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0361-bird-on-a-branch-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0364-peacock-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0364-peacock/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0364-peacock-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0366-roadrunner-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0366-roadrunner/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0366-roadrunner-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0379-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0379-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0379-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0383-crow-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0383-crow/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0383-crow-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0384-bird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0384-bird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0384-bird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0388-ostrich-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0388-ostrich/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0388-ostrich-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0389-bird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0389-bird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0389-bird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0393-ostrich-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0393-ostrich/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0393-ostrich-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0404-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0404-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0404-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0406-macaw-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0406-macaw/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0406-macaw-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0425-bluebird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0425-bluebird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0425-bluebird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0427-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0427-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0427-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0428-bird-of-paradise-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0428-bird-of-paradise/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0428-bird-of-paradise-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0432-hawk-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0432-hawk/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0432-hawk-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0433-roadrunner-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0433-roadrunner/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0433-roadrunner-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0439-jay-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0439-jay/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0439-jay-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0442-eagle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0442-eagle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0442-eagle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0445-macaw-parrot-portrait-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0445-macaw-parrot-portrait/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0445-macaw-parrot-portrait-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0453-hummingbird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0453-hummingbird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0453-hummingbird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0463-sparrow-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0463-sparrow/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0463-sparrow-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0464-bird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0464-bird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0464-bird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0468-stork-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0468-stork/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0468-stork-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0473-bird-in-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0473-bird-in-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0473-bird-in-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0477-seagulls-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0477-seagulls/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0477-seagulls-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0478-parrots-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0478-parrots/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0478-parrots-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0480-jay-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0480-jay/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0480-jay-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0486-bluebird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0486-bluebird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0486-bluebird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0492-eagle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0492-eagle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0492-eagle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0498-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0498-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0498-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0507-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0507-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0507-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0518-cuckoo-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0518-cuckoo/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0518-cuckoo-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0520-duck-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0520-duck/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0520-duck-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0559-parrot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0559-parrot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0559-parrot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0574-jay-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0574-jay/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0574-jay-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0599-northern-cardinal-bird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0599-northern-cardinal-bird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0599-northern-cardinal-bird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0620-raven-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0620-raven/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0620-raven-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0626-bluebird-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0626-bluebird/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0626-bluebird-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0654-owl-sitting-on-a-mug-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0654-owl-sitting-on-a-mug/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0654-owl-sitting-on-a-mug-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0658-parrot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0658-parrot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0658-parrot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0659-baby-owl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0659-baby-owl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0659-baby-owl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0661-eagle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0661-eagle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0661-eagle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0674-pigeon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0674-pigeon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0674-pigeon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0676-seagull-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0676-seagull/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0676-seagull-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0683-cute-eagle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0683-cute-eagle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0683-cute-eagle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0689-parrot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0689-parrot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0689-parrot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0697-flamingos-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0697-flamingos/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0697-flamingos-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0708-seagull-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/birds/0708-seagull/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-birds-0708-seagull-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-butterflies-0028-butterfly-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/butterflies/0028-butterfly/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-butterflies-0028-butterfly-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-butterflies-0280-butterfly-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/butterflies/0280-butterfly/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-butterflies-0280-butterfly-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-butterflies-0440-butterfly-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/butterflies/0440-butterfly/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-butterflies-0440-butterfly-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-butterflies-0525-butterfly-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/butterflies/0525-butterfly/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-butterflies-0525-butterfly-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-butterflies-0619-butterfly-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/butterflies/0619-butterfly/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-butterflies-0619-butterfly-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0024-norwegian-forest-cat-portrait-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0024-norwegian-forest-cat-portrait/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0024-norwegian-forest-cat-portrait-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0036-american-bobtail-cat-portrait-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0036-american-bobtail-cat-portrait/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0036-american-bobtail-cat-portrait-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0052-norwegian-forest-cat-portrait-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0052-norwegian-forest-cat-portrait/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0052-norwegian-forest-cat-portrait-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0055-cat-in-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0055-cat-in-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0055-cat-in-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0073-cat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0073-cat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0073-cat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0078-turkish-angora-cat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0078-turkish-angora-cat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0078-turkish-angora-cat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0083-cat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0083-cat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0083-cat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0095-birman-cat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0095-birman cat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0095-birman-cat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0111-kitten-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0111-kitten/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0111-kitten-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0125-cat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0125-cat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0125-cat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0143-cat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0143-cat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0143-cat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0155-cat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0155-cat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0155-cat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0173-cute-cat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0173-cute-cat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0173-cute-cat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0256-cat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0256-cat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0256-cat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0268-cute-kitten-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0268-cute-kitten/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0268-cute-kitten-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0392-cat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0392-cat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0392-cat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0437-kitten-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0437-kitten/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0437-kitten-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0560-cat-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0560-cat-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0560-cat-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0688-cute-kitten-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/cats/0688-cute-kitten/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-cats-0688-cute-kitten-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0082-bulldog-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0082-bulldog/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0082-bulldog-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0109-cute-puppy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0109-cute-puppy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0109-cute-puppy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0117-puppy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0117-puppy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0117-puppy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0148-dog-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0148-dog/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0148-dog-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0290-labrador-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0290-labrador/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0290-labrador-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0292-puppy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0292-puppy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0292-puppy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0418-dachshund-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0418-dachshund/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0418-dachshund-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0424-poodle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0424-poodle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0424-poodle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0476-puppy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0476-puppy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0476-puppy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0489-baby-husky-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0489-baby-husky/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0489-baby-husky-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0511-husky-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0511-husky/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0511-husky-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0514-poodle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0514-poodle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0514-poodle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0578-poodle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0578-poodle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0578-poodle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0595-chihuahua-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0595-chihuahua/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0595-chihuahua-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0618-bulldog-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0618-bulldog/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0618-bulldog-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0669-puppy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0669-puppy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0669-puppy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0692-labrador-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0692-labrador/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0692-labrador-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0723-puppy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/dogs/0723-puppy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-dogs-0723-puppy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0060-fish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0060-fish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0060-fish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0186-cute-seahorse-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0186-cute-seahorse/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0186-cute-seahorse-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0190-tuna-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0190-tuna/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0190-tuna-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0191-little-shark-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0191-little-shark/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0191-little-shark-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0238-smiling-fish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0238-smiling-fish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0238-smiling-fish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0299-fish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0299-fish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0299-fish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0311-fishes-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0311-fishes/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0311-fishes-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0332-coralfish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0332-coralfish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0332-coralfish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0429-marlin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0429-marlin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0429-marlin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0436-angelfish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0436-angelfish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0436-angelfish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0493-smiling-fish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0493-smiling-fish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0493-smiling-fish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0501-tilapia-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0501-tilapia/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0501-tilapia-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0531-coral-reef-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0531-coral-reef/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0531-coral-reef-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0589-coralfish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0589-coralfish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0589-coralfish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0596-angelfish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0596-angelfish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0596-angelfish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0600-betta-fish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0600-betta-fish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0600-betta-fish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0652-fish-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0652-fish-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0652-fish-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0663-angelfish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0663-angelfish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0663-angelfish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0673-clownfish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0673-clownfish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0673-clownfish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0682-fish-with-big-eyes-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0682-fish-with-big-eyes/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0682-fish-with-big-eyes-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0726-coralfish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/fish/0726-coralfish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-fish-0726-coralfish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0087-cicada-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0087-cicada/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0087-cicada-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0131-moth-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0131-moth/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0131-moth-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0203-cicada-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0203-cicada/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0203-cicada-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0209-dragonfly-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0209-dragonfly/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0209-dragonfly-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0222-smiling-bug-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0222-smiling-bug/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0222-smiling-bug-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0330-cute-bee-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0330-cute-bee/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0330-cute-bee-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0457-dragonfly-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0457-dragonfly/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0457-dragonfly-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0590-locust-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0590-locust/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0590-locust-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0594-cockroach-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0594-cockroach/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0594-cockroach-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0610-smiling-bee-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0610-smiling-bee/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0610-smiling-bee-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0614-earwig-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/insects/0614-earwig/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-insects-0614-earwig-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0063-little-pony-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0063-little-pony/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0063-little-pony-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0124-simple-sheep-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0124-simple-sheep/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0124-simple-sheep-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0167-little-hippo-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0167-little-hippo/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0167-little-hippo-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0171-horse-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0171-horse/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0171-horse-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0187-funny-spider-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0187-funny-spider/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0187-funny-spider-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0200-little-pig-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0200-little-pig/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0200-little-pig-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0259-horse-portrait-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0259-horse-portrait/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0259-horse-portrait-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0274-baby-seal-on-ice-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0274-baby-seal-on-ice/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0274-baby-seal-on-ice-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0281-little-horse-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0281-little-horse/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0281-little-horse-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0305-penguin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0305-penguin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0305-penguin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0321-penguin-with-hat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0321-penguin-with-hat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0321-penguin-with-hat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0345-spider-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0345-spider/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0345-spider-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0376-hamster-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0376-hamster/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0376-hamster-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0500-penguin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0500-penguin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0500-penguin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0527-rooster-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0527-rooster/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0527-rooster-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0554-galloping-horse-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0554-galloping-horse/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0554-galloping-horse-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0593-smiling-dinosaur-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0593-smiling-dinosaur/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0593-smiling-dinosaur-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0606-smiling-dinosaur-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0606-smiling-dinosaur/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0606-smiling-dinosaur-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0617-buffalo-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0617-buffalo/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0617-buffalo-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0624-kangaroo-with-boxing-gloves-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0624-kangaroo-with-boxing-gloves/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0624-kangaroo-with-boxing-gloves-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0637-rooster-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0637-rooster/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0637-rooster-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0641-bunny-with-a-carrot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0641-bunny-with-a-carrot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0641-bunny-with-a-carrot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0642-duck-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0642-duck/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0642-duck-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0660-duck-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0660-duck/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0660-duck-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0668-dinosaur-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0668-dinosaur/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0668-dinosaur-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0670-penguin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0670-penguin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0670-penguin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0671-smiling-bat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0671-smiling-bat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0671-smiling-bat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0672-funny-crocodile-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0672-funny-crocodile/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0672-funny-crocodile-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0677-jellyfish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0677-jellyfish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0677-jellyfish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0679-pig-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0679-pig/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0679-pig-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0680-smiling-dinosaur-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0680-smiling-dinosaur/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0680-smiling-dinosaur-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0704-octopus-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0704-octopus/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0704-octopus-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0711-walrus-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0711-walrus/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0711-walrus-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0730-smiling-jellyfish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/other/0730-smiling-jellyfish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-other-0730-smiling-jellyfish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0001-badger-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0001-badger/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0001-badger-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0008-crocodile-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0008-crocodile/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0008-crocodile-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0014-jellyfish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0014-jellyfish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0014-jellyfish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0039-wolf-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0039-wolf/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0039-wolf-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0040-fawn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0040-fawn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0040-fawn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0049-squirrel-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0049-squirrel/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0049-squirrel-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0067-squirrel-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0067-squirrel/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0067-squirrel-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0093-snake-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0093-snake/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0093-snake-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0112-lion-with-a-crown-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0112-lion-with-a-crown/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0112-lion-with-a-crown-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0153-hedgehog-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0153-hedgehog/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0153-hedgehog-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0157-little-elephant-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0157-little-elephant/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0157-little-elephant-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0161-skunk-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0161-skunk/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0161-skunk-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0176-hedgehog-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0176-hedgehog/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0176-hedgehog-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0184-squirrel-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0184-squirrel/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0184-squirrel-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0185-little-bear-with-honey-jar-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0185-little-bear-with-honey-jar/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0185-little-bear-with-honey-jar-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0192-baby-bear-with-pot-of-honey-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0192-baby-bear-with-pot-of-honey/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0192-baby-bear-with-pot-of-honey-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0195-skunk-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0195-skunk/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0195-skunk-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0207-lion-with-crown-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0207-lion-with-crown/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0207-lion-with-crown-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0214-little-bear-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0214-little-bear/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0214-little-bear-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0221-elk-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0221-elk/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0221-elk-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0225-baby-rabbit-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0225-baby-rabbit/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0225-baby-rabbit-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0234-fox-portrait-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0234-fox-portrait/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0234-fox-portrait-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0236-funny-meerkat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0236-funny-meerkat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0236-funny-meerkat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0241-baby-rabbit-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0241-baby-rabbit/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0241-baby-rabbit-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0247-monkey-with-hat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0247-monkey-with-hat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0247-monkey-with-hat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0248-fawn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0248-fawn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0248-fawn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0252-squirrel-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0252-squirrel/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0252-squirrel-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0282-penguin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0282-penguin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0282-penguin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0284-little-buffalo-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0284-little-buffalo/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0284-little-buffalo-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0293-cute-panther-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0293-cute-panther/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0293-cute-panther-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0296-cute-rabbit-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0296-cute-rabbit/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0296-cute-rabbit-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0308-reindeer-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0308-reindeer/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0308-reindeer-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0319-simple-penguin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0319-simple-penguin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0319-simple-penguin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0320-funny-crocodile-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0320-funny-crocodile/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0320-funny-crocodile-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0324-chameleon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0324-chameleon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0324-chameleon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0340-giraffe-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0340-giraffe/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0340-giraffe-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0359-panda-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0359-panda/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0359-panda-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0365-tortoise-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0365-tortoise/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0365-tortoise-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0371-squirrel-in-hat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0371-squirrel-in-hat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0371-squirrel-in-hat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0381-ape-in-vest-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0381-ape-in-vest/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0381-ape-in-vest-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0382-leopard-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0382-leopard/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0382-leopard-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0412-turtles-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0412-turtles/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0412-turtles-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0413-little-bear-with-fish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0413-little-bear-with-fish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0413-little-bear-with-fish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0414-walrus-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0414-walrus/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0414-walrus-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0422-wolf-potrait-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0422-wolf-potrait/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0422-wolf-potrait-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0426-elk-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0426-elk/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0426-elk-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0441-wolf-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0441-wolf/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0441-wolf-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0443-reindeer-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0443-reindeer/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0443-reindeer-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0450-giraffe-portrait-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0450-giraffe-portrait/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0450-giraffe-portrait-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0458-baby-dear-in-crown-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0458-baby-dear-in-crown/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0458-baby-dear-in-crown-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0459-fox-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0459-fox/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0459-fox-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0460-dear-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0460-dear/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0460-dear-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0466-baby-rabbit-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0466-baby-rabbit/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0466-baby-rabbit-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0467-baby-giraffe-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0467-baby-giraffe/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0467-baby-giraffe-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0485-tortoise-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0485-tortoise/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0485-tortoise-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0491-jaguar-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0491-jaguar/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0491-jaguar-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0499-baby-bunny-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0499-baby-bunny/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0499-baby-bunny-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0502-frog-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0502-frog/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0502-frog-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0510-baby-wolf-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0510-baby-wolf/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0510-baby-wolf-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0513-hedgehog-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0513-hedgehog/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0513-hedgehog-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0516-baby-fox-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0516-baby-fox/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0516-baby-fox-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0524-simple-squirrel-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0524-simple-squirrel/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0524-simple-squirrel-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0528-squirrel-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0528-squirrel/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0528-squirrel-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0537-squirrel-in-hat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0537-squirrel-in-hat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0537-squirrel-in-hat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0543-funny-crocodile-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0543-funny-crocodile/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0543-funny-crocodile-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0557-koala-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0557-koala/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0557-koala-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0563-racoon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0563-racoon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0563-racoon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0566-reindeer-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0566-reindeer/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0566-reindeer-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0572-tiger-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0572-tiger/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0572-tiger-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0575-baby-wolf-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0575-baby-wolf/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0575-baby-wolf-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0579-rabbit-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0579-rabbit/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0579-rabbit-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0586-cute-lion-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0586-cute-lion/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0586-cute-lion-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0587-panther-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0587-panther/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0587-panther-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0598-baby-gorilla-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0598-baby-gorilla/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0598-baby-gorilla-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0602-baby-fox-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0602-baby-fox/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0602-baby-fox-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0623-cute-reindeer-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0623-cute-reindeer/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0623-cute-reindeer-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0628-cute-emu-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0628-cute-emu/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0628-cute-emu-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0639-baby-bear-with-fish-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0639-baby-bear-with-fish/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0639-baby-bear-with-fish-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0662-baby-reindeer-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0662-baby-reindeer/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0662-baby-reindeer-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0665-turtle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0665-turtle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0665-turtle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0666-wolf-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0666-wolf/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0666-wolf-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0678-baby-elephant-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0678-baby-elephant/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0678-baby-elephant-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0686-cute-lion-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0686-cute-lion/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0686-cute-lion-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0695-baby-fox-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/animals/wild/0695-baby-fox/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-animals-wild-0695-baby-fox-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0003-hotel-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0003-hotel/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0003-hotel-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0035-windmill-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0035-windmill/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0035-windmill-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0050-lighthouse-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0050-lighthouse/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0050-lighthouse-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0054-small-house-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0054-small-house/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0054-small-house-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0085-simple-factory-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0085-simple-factory/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0085-simple-factory-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0129-fantasy-interior-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0129-fantasy-interior/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0129-fantasy-interior-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0181-house-in-a-forest-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0181-house-in-a-forest/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0181-house-in-a-forest-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0235-confectionery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0235-confectionery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0235-confectionery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0255-small-store-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0255-small-store/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0255-small-store-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0258-store-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0258-store/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0258-store-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0275-fantasy-house-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0275-fantasy-house/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0275-fantasy-house-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0285-stall-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0285-stall/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0285-stall-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0297-store-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0297-store/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0297-store-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0363-factory-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0363-factory/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0363-factory-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0446-fantasy-mushroom-house-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0446-fantasy-mushroom-house/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0446-fantasy-mushroom-house-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0505-ruins-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0505-ruins/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0505-ruins-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0632-factory-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/architecture/0632-factory/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-architecture-0632-factory-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0004-knight-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0004-knight/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0004-knight-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0072-princess-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0072-princess/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0072-princess-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0298-elf-girl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0298-elf-girl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0298-elf-girl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0348-vampire-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0348-vampire/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0348-vampire-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0369-witch-sitting-on-a-pumpkin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0369-witch-sitting-on-a-pumpkin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0369-witch-sitting-on-a-pumpkin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0385-funny-hero-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0385-funny-hero/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0385-funny-hero-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0423-knight-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0423-knight/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0423-knight-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0462-fantasy-witch-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0462-fantasy-witch/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0462-fantasy-witch-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0551-scarecrow-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0551-scarecrow/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0551-scarecrow-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0558-futuristic-knight-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0558-futuristic-knight/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0558-futuristic-knight-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0565-skeleton-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0565-skeleton/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0565-skeleton-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0591-fantasy-female-with-hat-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0591-fantasy-female-with-hat/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0591-fantasy-female-with-hat-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0605-fantasy-female-knight-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0605-fantasy-female-knight/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0605-fantasy-female-knight-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0608-fantasy-witch-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0608-fantasy-witch/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0608-fantasy-witch-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0609-princess-in-enchanted-forest-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0609-princess-in-enchanted-forest/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0609-princess-in-enchanted-forest-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0627-fantasy-female-with-gryphons-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0627-fantasy-female-with-gryphons/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0627-fantasy-female-with-gryphons-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0630-fantasy-female-warrior-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0630-fantasy-female-warrior/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0630-fantasy-female-warrior-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0634-female-elf-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0634-female-elf/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0634-female-elf-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0636-fantasy-character-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0636-fantasy-character/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0636-fantasy-character-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0644-female-priest-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0644-female-priest/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0644-female-priest-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0649-spider-queen-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0649-spider-queen/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0649-spider-queen-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0653-female-wizard-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0653-female-wizard/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0653-female-wizard-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0655-fantasy-queen-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0655-fantasy-queen/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0655-fantasy-queen-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0657-fantasy-queen-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0657-fantasy-queen/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0657-fantasy-queen-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0693-fantasy-female-character-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0693-fantasy-female-character/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0693-fantasy-female-character-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0714-fantasy-female-character-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0714-fantasy-female-character/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0714-fantasy-female-character-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0721-fantasy-witch-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0721-fantasy-witch/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0721-fantasy-witch-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0727-girl-in-enchanted-forest-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0727-girl-in-enchanted-forest/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0727-girl-in-enchanted-forest-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0729-witch-apprentice-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0729-witch-apprentice/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0729-witch-apprentice-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0733-fantasy-witch-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0733-fantasy-witch/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0733-fantasy-witch-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0734-male-character-with-wings-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0734-male-character-with-wings/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0734-male-character-with-wings-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0735-male-warrior-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0735-male-warrior/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0735-male-warrior-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0738-male-magician-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0738-male-magician/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0738-male-magician-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0740-gunslinger-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0740-gunslinger/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0740-gunslinger-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0743-male-mage-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0743-male-mage/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0743-male-mage-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0744-male-hunter-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0744-male-hunter/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0744-male-hunter-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0745-male-martial-artist-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0745-male-martial-artist/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0745-male-martial-artist-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0749-ninja-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/characters/0749-ninja/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-characters-0749-ninja-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0009-fantasy-creature-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0009-fantasy-creature/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0009-fantasy-creature-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0013-fantasy-creature-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0013-fantasy-creature/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0013-fantasy-creature-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0021-fantasy-creature-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0021-fantasy-creature/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0021-fantasy-creature-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0030-dragons-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0030-dragons/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0030-dragons-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0051-little-dragons-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0051-little-dragons/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0051-little-dragons-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0057-gnomes-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0057-gnomes/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0057-gnomes-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0062-little-griffin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0062-little-griffin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0062-little-griffin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0070-fantasy-animals-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0070-fantasy-animals/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0070-fantasy-animals-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0071-gnome-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0071-gnome/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0071-gnome-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0074-fantasy-animals-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0074-fantasy-animals/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0074-fantasy-animals-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0090-gnomes-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0090-gnomes/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0090-gnomes-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0107-baby-gargoyles-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0107-baby-gargoyles/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0107-baby-gargoyles-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0126-dragon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0126-dragon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0126-dragon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0133-triceratops-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0133-triceratops/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0133-triceratops-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0138-fantasy-animal-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0138-fantasy-animal/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0138-fantasy-animal-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0144-fantasy-animals-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0144-fantasy-animals/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0144-fantasy-animals-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0175-fantasy-animals-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0175-fantasy-animals/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0175-fantasy-animals-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0193-sitting-dragon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0193-sitting-dragon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0193-sitting-dragon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0211-little-dinosaur-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0211-little-dinosaur/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0211-little-dinosaur-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0232-dragon-head-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0232-dragon-head/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0232-dragon-head-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0334-fantasy-creature-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0334-fantasy-creature/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0334-fantasy-creature-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0474-dragon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0474-dragon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0474-dragon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0536-baby-dragon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0536-baby-dragon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0536-baby-dragon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0544-werewolf-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0544-werewolf/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0544-werewolf-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0548-yeti-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0548-yeti/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0548-yeti-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0555-gnomes-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0555-gnomes/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0555-gnomes-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0556-baby-dragon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0556-baby-dragon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0556-baby-dragon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0561-yeti-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0561-yeti/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0561-yeti-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0571-phoenix-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0571-phoenix/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0571-phoenix-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0603-fantasy-insect-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0603-fantasy-insect/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0603-fantasy-insect-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0656-little-goblin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0656-little-goblin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0656-little-goblin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0685-fantasy-baby-dragon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0685-fantasy-baby-dragon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0685-fantasy-baby-dragon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0736-fantasy-minotaur-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0736-fantasy-minotaur/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0736-fantasy-minotaur-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0737-fantasy-ogre-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0737-fantasy-ogre/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0737-fantasy-ogre-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0739-fantasy-gnome-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0739-fantasy-gnome/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0739-fantasy-gnome-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0741-goblin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0741-goblin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0741-goblin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0742-fantasy-troll-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0742-fantasy-troll/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0742-fantasy-troll-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0746-werewolf-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0746-werewolf/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0746-werewolf-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0747-fantasy-ogre-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0747-fantasy-ogre/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0747-fantasy-ogre-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0748-fantasy-gnome-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0748-fantasy-gnome/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0748-fantasy-gnome-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0750-fantasy-gnome-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/creatures/0750-fantasy-gnome/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-creatures-0750-fantasy-gnome-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-fairies-0120-fairy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/fairies/0120-fairy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-fairies-0120-fairy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-fairies-0346-fairy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/fairies/0346-fairy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-fairies-0346-fairy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-fairies-0535-fairy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/fairies/0535-fairy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-fairies-0535-fairy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-fairies-0573-fairy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/fairies/0573-fairy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-fairies-0573-fairy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-fairies-0651-little-fairy-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/fairies/0651-little-fairy/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-fairies-0651-little-fairy-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0128-pirate-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/pirates/0128-pirate/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0128-pirate-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0249-pirate-girl-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/pirates/0249-pirate-girl/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0249-pirate-girl-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0564-pirate-skull-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/pirates/0564-pirate-skull/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0564-pirate-skull-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0629-pirate-ship-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/pirates/0629-pirate-ship/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0629-pirate-ship-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0719-pirate-kids-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/pirates/0719-pirate-kids/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0719-pirate-kids-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0732-female-pirate-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/pirates/0732-female-pirate/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-pirates-0732-female-pirate-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-things-0310-necromancer-books-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/things/0310-necromancer-books/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-things-0310-necromancer-books-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-things-0604-fantasy-mushroom-house-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/things/0604-fantasy-mushroom-house/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-things-0604-fantasy-mushroom-house-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0007-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0007-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0007-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0010-little-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0010-little-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0010-little-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0048-pegasus-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0048-pegasus/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0048-pegasus-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0080-pegasus-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0080-pegasus/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0080-pegasus-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0091-pegasus-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0091-pegasus/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0091-pegasus-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0134-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0134-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0134-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0198-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0198-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0198-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0267-simple-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0267-simple-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0267-simple-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0357-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0357-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0357-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0538-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0538-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0538-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0540-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0540-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0540-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0546-cute-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0546-cute-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0546-cute-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0547-unicorn-in-a-forest-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0547-unicorn-in-a-forest/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0547-unicorn-in-a-forest-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0569-unicorn-with-butterflies-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0569-unicorn-with-butterflies/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0569-unicorn-with-butterflies-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0681-pegasus-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0681-pegasus/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0681-pegasus-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0722-unicorn-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/unicorns/0722-unicorn/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-unicorns-0722-unicorn-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-zombie-0139-funny-zombie-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/zombie/0139-funny-zombie/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-zombie-0139-funny-zombie-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-zombie-0263-funny-zombie-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/zombie/0263-funny-zombie/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-zombie-0263-funny-zombie-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-zombie-0612-funny-zombie-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fantasy/zombie/0612-funny-zombie/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fantasy-zombie-0612-funny-zombie-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0018-girl-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0018-girl-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0018-girl-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0020-camellia-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0020-camellia-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0020-camellia-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0022-gladiolus-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0022-gladiolus-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0022-gladiolus-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0025-bottle-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0025-bottle-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0025-bottle-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0026-laurel-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0026-laurel-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0026-laurel-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0053-orchid-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0053-orchid-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0053-orchid-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0064-girl-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0064-girl-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0064-girl-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0065-hibiscus-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0065-hibiscus-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0065-hibiscus-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0066-fantasy-creature-in-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0066-fantasy-creature-in-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0066-fantasy-creature-in-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0069-dahlia-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0069-dahlia-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0069-dahlia-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0089-flower-decoration-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0089-flower-decoration/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0089-flower-decoration-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0096-flower-decoration-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0096-flower-decoration/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0096-flower-decoration-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0101-girl-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0101-girl-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0101-girl-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0105-laurel-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0105-laurel-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0105-laurel-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0119-vase-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0119-vase-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0119-vase-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0122-kids-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0122-kids-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0122-kids-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0145-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0145-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0145-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0160-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0160-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0160-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0164-buttercup-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0164-buttercup-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0164-buttercup-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0165-flower-decoration-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0165-flower-decoration/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0165-flower-decoration-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0168-poinsettia-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0168-poinsettia-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0168-poinsettia-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0172-lillies-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0172-lillies-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0172-lillies-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0199-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0199-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0199-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0201-gladiolus-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0201-gladiolus-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0201-gladiolus-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0205-poinsettia-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0205-poinsettia-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0205-poinsettia-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0218-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0218-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0218-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0220-primrose-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0220-primrose-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0220-primrose-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0231-vase-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0231-vase-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0231-vase-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0246-chrysanthemum-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0246-chrysanthemum-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0246-chrysanthemum-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0251-jar-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0251-jar-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0251-jar-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0254-rose-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0254-rose-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0254-rose-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0269-hibiscus-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0269-hibiscus-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0269-hibiscus-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0270-nasturtium-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0270-nasturtium-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0270-nasturtium-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0278-vase-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0278-vase-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0278-vase-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0294-bottle-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0294-bottle-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0294-bottle-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0302-orchid-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0302-orchid-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0302-orchid-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0313-roses-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0313-roses/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0313-roses-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0316-butterfly-in-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0316-butterfly-in-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0316-butterfly-in-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0318-flower-decoration-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0318-flower-decoration/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0318-flower-decoration-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0327-hyacinthus-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0327-hyacinthus-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0327-hyacinthus-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0333-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0333-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0333-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0337-buttercup-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0337-buttercup-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0337-buttercup-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0339-aster-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0339-aster-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0339-aster-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0343-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0343-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0343-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0351-columbine-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0351-columbine-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0351-columbine-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0354-simple-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0354-simple-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0354-simple-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0370-lilies-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0370-lilies-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0370-lilies-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0372-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0372-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0372-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0380-zinnia-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0380-zinnia-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0380-zinnia-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0386-peony-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0386-peony-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0386-peony-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0391-primrose-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0391-primrose-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0391-primrose-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0435-leaves-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0435-leaves-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0435-leaves-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0438-leaves-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0438-leaves-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0438-leaves-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0447-azalea-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0447-azalea-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0447-azalea-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0455-frame-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0455-frame-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0455-frame-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0456-lilies-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0456-lilies-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0456-lilies-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0470-heart-shaped-frame-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0470-heart-shaped-frame-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0470-heart-shaped-frame-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0471-gardenia-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0471-gardenia-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0471-gardenia-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0472-girls-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0472-girls-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0472-girls-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0475-gladiolus-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0475-gladiolus-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0475-gladiolus-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0481-hibiscus-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0481-hibiscus-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0481-hibiscus-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0487-bluebird-in-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0487-bluebird-in-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0487-bluebird-in-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0495-carnation-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0495-carnation-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0495-carnation-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0497-girl-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0497-girl-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0497-girl-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0529-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0529-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0529-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0541-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0541-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0541-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0542-girl-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0542-girl-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0542-girl-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0553-bouquet-of-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0553-bouquet-of-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0553-bouquet-of-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0567-amaryllis-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0567-amaryllis-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0567-amaryllis-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0568-hyacinthus-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0568-hyacinthus-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0568-hyacinthus-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0576-bouquet-of-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0576-bouquet-of-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0576-bouquet-of-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0577-girl-with-bouquet-of-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0577-girl-with-bouquet-of-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0577-girl-with-bouquet-of-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0582-daffodil-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0582-daffodil-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0582-daffodil-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0583-bouquet-of-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0583-bouquet-of-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0583-bouquet-of-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0588-orchid-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0588-orchid-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0588-orchid-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0597-tulips-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0597-tulips/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0597-tulips-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0611-bouquet-of-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0611-bouquet-of-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0611-bouquet-of-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0625-lilies-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0625-lilies/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0625-lilies-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0640-black-eyed-susan-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0640-black-eyed-susan-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0640-black-eyed-susan-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0664-wildflower-bouquet-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0664-wildflower-bouquet/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0664-wildflower-bouquet-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0667-daisy-bouquet-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0667-daisy-bouquet/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0667-daisy-bouquet-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0684-orchid-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0684-orchid-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0684-orchid-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0687-roses-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0687-roses/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0687-roses-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0699-roses-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0699-roses/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0699-roses-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0724-cherry-blossom-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0724-cherry-blossom/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0724-cherry-blossom-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0725-lily-and-rose-bouquet-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0725-lily-and-rose-bouquet/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0725-lily-and-rose-bouquet-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0728-tulips-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0728-tulips/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0728-tulips-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0731-gladiolus-flower-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/flowers/0731-gladiolus-flower/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-flowers-0731-gladiolus-flower-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0108-hamburger-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/food/0108-hamburger/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0108-hamburger-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0223-hamburger-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/food/0223-hamburger/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0223-hamburger-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0352-sandwich-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/food/0352-sandwich/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0352-sandwich-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0375-beans-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/food/0375-beans/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0375-beans-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0402-slice-of-pizza-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/food/0402-slice-of-pizza/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0402-slice-of-pizza-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0415-hamburger-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/food/0415-hamburger/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0415-hamburger-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0503-meal-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/food/0503-meal/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0503-meal-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0715-meal-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/food/0715-meal/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-food-0715-meal-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0005-smiling-avocado-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0005-smiling-avocado/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0005-smiling-avocado-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0027-smiling-avocado-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0027-smiling-avocado/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0027-smiling-avocado-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0029-smiling-watermelon-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0029-smiling-watermelon/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0029-smiling-watermelon-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0076-apricot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0076-apricot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0076-apricot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0084-durian-fruit-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0084-durian-fruit/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0084-durian-fruit-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0136-smiling-apple-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0136-smiling-apple/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0136-smiling-apple-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0177-grapes-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0177-grapes/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0177-grapes-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0217-banana-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0217-banana/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0217-banana-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0226-smiling-banana-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0226-smiling-banana/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0226-smiling-banana-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0239-grapes-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0239-grapes/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0239-grapes-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0242-grapes-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0242-grapes/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0242-grapes-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0257-pineapples-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0257-pineapples/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0257-pineapples-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0286-grapes-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0286-grapes/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0286-grapes-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0328-pineapple-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0328-pineapple/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0328-pineapple-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0336-bananas-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0336-bananas/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0336-bananas-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0368-elderberry-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0368-elderberry/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0368-elderberry-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0373-smiling-grapefruit-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0373-smiling-grapefruit/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0373-smiling-grapefruit-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0400-strawberry-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0400-strawberry/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0400-strawberry-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0409-plums-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0409-plums/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0409-plums-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0411-strawberry-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0411-strawberry/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0411-strawberry-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0431-gooseberry-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0431-gooseberry/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0431-gooseberry-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0490-quince-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0490-quince/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0490-quince-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0494-plums-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0494-plums/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0494-plums-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0534-apricot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0534-apricot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0534-apricot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0581-blackberry-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0581-blackberry/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0581-blackberry-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0592-smiling-elderberry-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0592-smiling-elderberry/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0592-smiling-elderberry-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0643-blueberry-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0643-blueberry/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0643-blueberry-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0648-jackfruit-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0648-jackfruit/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0648-jackfruit-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0696-pineapple-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0696-pineapple/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0696-pineapple-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0702-gooseberry-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/fruits/0702-gooseberry/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-fruits-0702-gooseberry-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0046-christmas-decoration-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0046-christmas-decoration/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0046-christmas-decoration-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0056-christmas-decoration-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0056-christmas-decoration/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0056-christmas-decoration-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0059-christmas-decoration-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0059-christmas-decoration/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0059-christmas-decoration-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0130-funny-cloud-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0130-funny-cloud/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0130-funny-cloud-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0178-snowman-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0178-snowman/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0178-snowman-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0197-snowman-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0197-snowman/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0197-snowman-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0204-heart-shape-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0204-heart-shape/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0204-heart-shape-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0206-snowman-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0206-snowman/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0206-snowman-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0210-shelf-with-potions-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0210-shelf-with-potions/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0210-shelf-with-potions-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0212-teddy-bear-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0212-teddy-bear/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0212-teddy-bear-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0213-gingerbread-house-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0213-gingerbread-house/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0213-gingerbread-house-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0216-snowman-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0216-snowman/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0216-snowman-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0253-toy-robot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0253-toy-robot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0253-toy-robot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0277-shelf-with-books-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0277-shelf-with-books/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0277-shelf-with-books-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0288-shelf-with-books-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0288-shelf-with-books/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0288-shelf-with-books-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0312-robot-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0312-robot/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0312-robot-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0325-shelf-with-books-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0325-shelf-with-books/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0325-shelf-with-books-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0360-shelf-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0360-shelf/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0360-shelf-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0506-gingerbread-man-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0506-gingerbread-man/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0506-gingerbread-man-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0552-gingerbread-man-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0552-gingerbread-man/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0552-gingerbread-man-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0635-gemstones-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/other/0635-gemstones/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-other-0635-gemstones-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-people-0121-girl-portrait-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/people/0121-girl-portrait/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-people-0121-girl-portrait-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-people-0156-girl-with-owls-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/people/0156-girl-with-owls/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-people-0156-girl-with-owls-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-people-0202-simple-girl-portrait-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/people/0202-simple-girl-portrait/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-people-0202-simple-girl-portrait-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-people-0315-girl-reading-a-book-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/people/0315-girl-reading-a-book/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-people-0315-girl-reading-a-book-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-people-0601-girl-in-a-garden-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/people/0601-girl-in-a-garden/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-people-0601-girl-in-a-garden-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0017-garden-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0017-garden/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0017-garden-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0043-fantasy-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0043-fantasy-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0043-fantasy-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0045-pool-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0045-pool/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0045-pool-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0058-mountain-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0058-mountain-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0058-mountain-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0097-waterfall-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0097-waterfall-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0097-waterfall-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0104-lake-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0104-lake-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0104-lake-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0106-lake-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0106-lake-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0106-lake-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0113-mountain-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0113-mountain-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0113-mountain-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0116-mountain-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0116-mountain-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0116-mountain-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0150-summery-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0150-summery-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0150-summery-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0180-fantasy-forest-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0180-fantasy-forest-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0180-fantasy-forest-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0183-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0183-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0183-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0196-rainbow-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0196-rainbow-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0196-rainbow-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0219-mountain-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0219-mountain-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0219-mountain-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0230-sea-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0230-sea-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0230-sea-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0264-house-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0264-house-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0264-house-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0271-volcano-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0271-volcano-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0271-volcano-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0329-countryside-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0329-countryside-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0329-countryside-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0335-rainforest-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0335-rainforest-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0335-rainforest-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0341-lighthouse-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0341-lighthouse-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0341-lighthouse-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0342-lake-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0342-lake-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0342-lake-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0378-tropical-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0378-tropical-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0378-tropical-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0387-lake-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0387-lake-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0387-lake-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0398-waterfall-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0398-waterfall-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0398-waterfall-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0401-mountain-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0401-mountain-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0401-mountain-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0448-island-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0448-island-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0448-island-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0449-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0449-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0449-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0451-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0451-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0451-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0454-beach-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0454-beach-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0454-beach-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0479-fantasy-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0479-fantasy-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0479-fantasy-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0482-forest-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0482-forest-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0482-forest-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0496-beach-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0496-beach-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0496-beach-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0515-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0515-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0515-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0517-river-with-bridge-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0517-river-with-bridge-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0517-river-with-bridge-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0519-river-with-bridge-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0519-river-with-bridge-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0519-river-with-bridge-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0522-lake-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0522-lake-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0522-lake-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0523-lake-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0523-lake-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0523-lake-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0526-mountain-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0526-mountain-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0526-mountain-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0539-desert-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0539-desert-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0539-desert-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0549-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0549-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0549-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0570-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0570-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0570-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0585-river-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0585-river-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0585-river-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0615-pirate-ship-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0615-pirate-ship-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0615-pirate-ship-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0633-forest-with-flowers-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0633-forest-with-flowers/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0633-forest-with-flowers-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0646-waterfall-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0646-waterfall-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0646-waterfall-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0647-castle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0647-castle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0647-castle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0698-coconut-trees-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0698-coconut-trees/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0698-coconut-trees-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0705-campfire-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0705-campfire/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0705-campfire-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0716-mushrooms-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0716-mushrooms/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0716-mushrooms-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0717-river-scenery-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/scenery/0717-river-scenery/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-scenery-0717-river-scenery-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0081-rocket-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/space/0081-rocket/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0081-rocket-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0260-rocket-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/space/0260-rocket/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0260-rocket-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0283-rocket-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/space/0283-rocket/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0283-rocket-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0303-rocket-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/space/0303-rocket/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0303-rocket-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0613-astronaut-on-another-planet-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/space/0613-astronaut-on-another-planet/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0613-astronaut-on-another-planet-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0621-rocket-launching-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/space/0621-rocket-launching/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-space-0621-rocket-launching-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0038-ice-cream-cone-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0038-ice-cream-cone/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0038-ice-cream-cone-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0041-cake-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0041-cake/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0041-cake-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0061-funny-icecream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0061-funny-icecream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0061-funny-icecream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0103-ice-cream-dessert-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0103-ice-cream-dessert/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0103-ice-cream-dessert-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0115-cake-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0115-cake/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0115-cake-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0137-smiling-cup-with-donut-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0137-smiling-cup-with-donut/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0137-smiling-cup-with-donut-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0140-ice-cream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0140-ice-cream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0140-ice-cream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0146-ice-cream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0146-ice-cream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0146-ice-cream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0149-ice-cream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0149-ice-cream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0149-ice-cream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0162-slice-of-cake-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0162-slice-of-cake/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0162-slice-of-cake-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0163-smiling-ice-cream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0163-smiling-ice-cream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0163-smiling-ice-cream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0166-ice-cream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0166-ice-cream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0166-ice-cream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0182-ice-cream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0182-ice-cream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0182-ice-cream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0265-ice-cream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0265-ice-cream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0265-ice-cream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0273-donut-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0273-donut/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0273-donut-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0276-muffin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0276-muffin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0276-muffin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0300-ice-cream-dessert-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0300-ice-cream-dessert/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0300-ice-cream-dessert-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0309-ice-cream-dessert-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0309-ice-cream-dessert/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0309-ice-cream-dessert-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0367-dessert-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0367-dessert/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0367-dessert-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0377-smiling-ice-cream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0377-smiling-ice-cream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0377-smiling-ice-cream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0390-cupcake-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0390-cupcake/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0390-cupcake-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0394-cake-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0394-cake/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0394-cake-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0408-muffins-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0408-muffins/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0408-muffins-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0419-smiling-muffin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0419-smiling-muffin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0419-smiling-muffin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0421-smiling-cupcake-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0421-smiling-cupcake/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0421-smiling-cupcake-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0452-cake-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0452-cake/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0452-cake-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0465-ice-cream-dessert-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0465-ice-cream-dessert/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0465-ice-cream-dessert-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0488-ice-cream-dessert-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0488-ice-cream-dessert/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0488-ice-cream-dessert-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0508-muffin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0508-muffin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0508-muffin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0616-dessert-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0616-dessert/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0616-dessert-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0631-smiling-ice-cream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0631-smiling-ice-cream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0631-smiling-ice-cream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0638-dessert-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0638-dessert/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0638-dessert-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0700-muffin-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0700-muffin/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0700-muffin-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0718-smiling-ice-cream-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0718-smiling-ice-cream/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0718-smiling-ice-cream-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0720-ice-creams-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/sweets/0720-ice-creams/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-sweets-0720-ice-creams-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0015-present-box-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0015-present-box/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0015-present-box-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0023-glass-bottle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0023-glass-bottle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0023-glass-bottle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0077-smiling-milk-box-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0077-smiling-milk-box/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0077-smiling-milk-box-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0098-bottle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0098-bottle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0098-bottle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0118-telescope-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0118-telescope/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0118-telescope-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0127-fishing-box-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0127-fishing-box/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0127-fishing-box-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0135-gas-mask-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0135-gas-mask/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0135-gas-mask-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0142-thermos-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0142-thermos/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0142-thermos-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0194-bottle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0194-bottle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0194-bottle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0228-boots-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0228-boots/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0228-boots-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0245-potion-bottle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0245-potion-bottle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0245-potion-bottle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0287-potion-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0287-potion/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0287-potion-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0291-backpack-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0291-backpack/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0291-backpack-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0301-golf-club-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0301-golf-club/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0301-golf-club-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0306-potions-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0306-potions/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0306-potions-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0307-boxing-gloves-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0307-boxing-gloves/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0307-boxing-gloves-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0317-backpack-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0317-backpack/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0317-backpack-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0323-potion-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0323-potion/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0323-potion-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0344-helmet-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0344-helmet/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0344-helmet-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0347-key-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0347-key/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0347-key-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0349-backpack-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0349-backpack/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0349-backpack-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0350-first-aid-kit-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0350-first-aid-kit/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0350-first-aid-kit-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0353-shield-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0353-shield/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0353-shield-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0355-helmet-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0355-helmet/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0355-helmet-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0362-boots-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0362-boots/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0362-boots-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0397-bottles-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0397-bottles/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0397-bottles-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0420-dreamcatcher-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0420-dreamcatcher/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0420-dreamcatcher-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0444-shoe-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0444-shoe/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0444-shoe-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0469-bootle-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0469-bootle/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0469-bootle-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0521-headphones-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0521-headphones/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0521-headphones-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0645-smiling-sun-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0645-smiling-sun/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0645-smiling-sun-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0690-backpack-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0690-backpack/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0690-backpack-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0691-anchor-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0691-anchor/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0691-anchor-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0694-boxing-gloves-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0694-boxing-gloves/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0694-boxing-gloves-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0706-emergency-raft-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0706-emergency-raft/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0706-emergency-raft-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0707-meal-box-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/things/0707-meal-box/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-things-0707-meal-box-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0002-bamboo-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0002-bamboo-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0002-bamboo-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0016-cherry-blossom-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0016-cherry-blossom/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0016-cherry-blossom-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0034-sequoia-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0034-sequoia-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0034-sequoia-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0042-chestnut-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0042-chestnut-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0042-chestnut-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0079-hemlock-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0079-hemlock-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0079-hemlock-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0094-willow-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0094-willow-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0094-willow-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0102-christmas-tree-decoration-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0102-christmas-tree-decoration/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0102-christmas-tree-decoration-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0110-leaves-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0110-leaves/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0110-leaves-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0123-cherry-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0123-cherry-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0123-cherry-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0147-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0147-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0147-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0151-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0151-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0151-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0152-apple-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0152-apple-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0152-apple-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0170-hawthorn-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0170-hawthorn-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0170-hawthorn-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0215-maples-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0215-maples-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0215-maples-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0295-chestnut-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0295-chestnut-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0295-chestnut-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0374-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0374-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0374-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0395-elm-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0395-elm-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0395-elm-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0405-bonsai-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0405-bonsai-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0405-bonsai-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0407-ash-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0407-ash-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0407-ash-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0416-larch-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0416-larch-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0416-larch-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0417-simple-cedar-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0417-simple-cedar-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0417-simple-cedar-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0430-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0430-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0430-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0509-hawthorn-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0509-hawthorn-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0509-hawthorn-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0530-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0530-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0530-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0562-trees-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0562-trees/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0562-trees-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0675-hemlock-tree-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/trees/0675-hemlock-tree/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-trees-0675-hemlock-tree-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0403-beets-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetables/0403-beets/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0403-beets-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0410-lemons-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetables/0410-lemons/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0410-lemons-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0461-smiling-onion-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetables/0461-smiling-onion/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0461-smiling-onion-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0532-pumpkins-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetables/0532-pumpkins/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0532-pumpkins-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0550-cabbages-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetables/0550-cabbages/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0550-cabbages-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0607-carrots-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetables/0607-carrots/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0607-carrots-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0709-broccoli-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetables/0709-broccoli/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0709-broccoli-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0710-lemons-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetables/0710-lemons/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0710-lemons-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0712-smiling-turnip-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetables/0712-smiling-turnip/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0712-smiling-turnip-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0713-cabbage-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetables/0713-cabbage/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetables-0713-cabbage-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0033-mushrooms-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetation/0033-mushrooms/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0033-mushrooms-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0399-mushrooms-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetation/0399-mushrooms/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0399-mushrooms-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0434-leaves-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetation/0434-leaves/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0434-leaves-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0483-mushrooms-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetation/0483-mushrooms/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0483-mushrooms-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0484-mushrooms-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetation/0484-mushrooms/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0484-mushrooms-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0504-mushrooms-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetation/0504-mushrooms/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0504-mushrooms-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0533-mushrooms-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetation/0533-mushrooms/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0533-mushrooms-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0703-cactus-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vegetation/0703-cactus/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vegetation-0703-cactus-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-cars-0114-bus-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/cars/0114-bus/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-cars-0114-bus-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-cars-0154-camper-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/cars/0154-camper/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-cars-0154-camper-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-cars-0279-quad-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/cars/0279-quad/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-cars-0279-quad-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-cars-0622-retro-car-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/cars/0622-retro-car/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-cars-0622-retro-car-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-cars-0701-retro-car-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/cars/0701-retro-car/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-cars-0701-retro-car-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-other-0229-excavator-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/other/0229-excavator/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-other-0229-excavator-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-other-0545-motorbike-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/other/0545-motorbike/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-other-0545-motorbike-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-other-0650-excavator-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/other/0650-excavator/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-other-0650-excavator-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0044-mining-ship-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/ships/0044-mining-ship/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0044-mining-ship-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0068-cruiser-ship-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/ships/0068-cruiser-ship/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0068-cruiser-ship-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0092-ship-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/ships/0092-ship/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0092-ship-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0188-rescue-ship-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/ships/0188-rescue-ship/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0188-rescue-ship-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0396-pirate-ship-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/ships/0396-pirate-ship/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0396-pirate-ship-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0512-cruise-ship-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/ships/0512-cruise-ship/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0512-cruise-ship-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0580-ship-on-a-stormy-sea-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/ships/0580-ship-on-a-stormy-sea/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0580-ship-on-a-stormy-sea-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0584-ship-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/ships/0584-ship/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-ships-0584-ship-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-trains-0169-bullet-train-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/trains/0169-bullet-train/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-trains-0169-bullet-train-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-trains-0179-train-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/trains/0179-train/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-trains-0179-train-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-trains-0289-train-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/trains/0289-train/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-trains-0289-train-index-mdx" */),
  "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-trains-0338-train-index-mdx": () => import("./../../../src/templates/coloring-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/color/creativity-land/coloring/vehicles/trains/0338-train/index.mdx" /* webpackChunkName: "component---src-templates-coloring-page-js-content-file-path-content-color-creativity-land-coloring-vehicles-trains-0338-train-index-mdx" */),
  "component---src-templates-coloring-page-listing-js": () => import("./../../../src/templates/coloring-page-listing.js" /* webpackChunkName: "component---src-templates-coloring-page-listing-js" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0012-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range100/math-add-0012/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0012-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0014-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range100/math-add-0014/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0014-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0016-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range100/math-add-0016/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0016-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0018-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range100/math-add-0018/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0018-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0020-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range100/math-add-0020/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0020-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0022-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range100/math-add-0022/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-100-math-add-0022-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0001-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range20/math-add-0001/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0001-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0002-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range20/math-add-0002/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0002-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0003-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range20/math-add-0003/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0003-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0004-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range20/math-add-0004/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0004-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0005-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range20/math-add-0005/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0005-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0006-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range20/math-add-0006/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-20-math-add-0006-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-200-math-add-0024-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range200/math-add-0024/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-200-math-add-0024-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-200-math-add-0025-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range200/math-add-0025/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-200-math-add-0025-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-200-math-add-0026-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range200/math-add-0026/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-200-math-add-0026-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-200-math-add-0027-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range200/math-add-0027/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-200-math-add-0027-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-200-math-add-0028-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range200/math-add-0028/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-200-math-add-0028-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0029-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range500/math-add-0029/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0029-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0030-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range500/math-add-0030/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0030-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0031-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range500/math-add-0031/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0031-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0032-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range500/math-add-0032/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0032-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0033-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range500/math-add-0033/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0033-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0034-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range500/math-add-0034/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0034-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0035-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/addition-subtraction/range500/math-add-0035/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-addition-subtraction-range-500-math-add-0035-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-mixed-simple-math-divide-0042-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/mixed-simple/math-divide-0042/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-mixed-simple-math-divide-0042-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-mixed-simple-math-divide-0043-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/mixed-simple/math-divide-0043/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-mixed-simple-math-divide-0043-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-mixed-simple-math-divide-0044-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/mixed-simple/math-divide-0044/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-mixed-simple-math-divide-0044-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0029-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range100/math-divide-0029/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0029-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0030-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range100/math-divide-0030/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0030-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0031-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range100/math-divide-0031/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0031-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0032-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range100/math-divide-0032/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0032-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0033-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range100/math-divide-0033/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0033-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0034-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range100/math-divide-0034/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0034-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0035-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range100/math-divide-0035/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-100-math-divide-0035-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-20-math-divide-0019-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range20/math-divide-0019/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-20-math-divide-0019-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-20-math-divide-0020-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range20/math-divide-0020/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-20-math-divide-0020-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-20-math-divide-0021-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range20/math-divide-0021/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-20-math-divide-0021-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-20-math-divide-0022-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range20/math-divide-0022/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-20-math-divide-0022-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0023-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range50/math-divide-0023/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0023-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0024-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range50/math-divide-0024/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0024-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0025-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range50/math-divide-0025/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0025-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0026-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range50/math-divide-0026/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0026-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0027-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range50/math-divide-0027/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0027-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0028-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range50/math-divide-0028/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-50-math-divide-0028-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0036-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range500/math-divide-0036/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0036-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0037-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range500/math-divide-0037/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0037-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0038-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range500/math-divide-0038/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0038-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0039-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range500/math-divide-0039/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0039-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0040-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range500/math-divide-0040/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0040-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0041-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/division/range500/math-divide-0041/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-division-range-500-math-divide-0041-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-100-math-multiply-0000-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range100/math-multiply-0000/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-100-math-multiply-0000-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-100-math-multiply-0001-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range100/math-multiply-0001/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-100-math-multiply-0001-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-100-math-multiply-0002-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range100/math-multiply-0002/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-100-math-multiply-0002-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-100-math-multiply-0003-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range100/math-multiply-0003/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-100-math-multiply-0003-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-100-math-multiply-0004-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range100/math-multiply-0004/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-100-math-multiply-0004-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-1000-math-multiply-0018-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range1000/math-multiply-0018/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-1000-math-multiply-0018-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0005-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range250/math-multiply-0005/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0005-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0006-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range250/math-multiply-0006/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0006-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0007-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range250/math-multiply-0007/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0007-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0008-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range250/math-multiply-0008/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0008-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0009-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range250/math-multiply-0009/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0009-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0010-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range250/math-multiply-0010/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-250-math-multiply-0010-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0011-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range500/math-multiply-0011/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0011-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0012-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range500/math-multiply-0012/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0012-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0013-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range500/math-multiply-0013/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0013-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0014-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range500/math-multiply-0014/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0014-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0015-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range500/math-multiply-0015/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0015-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0016-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range500/math-multiply-0016/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0016-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0017-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/math/multiplication/range500/math-multiply-0017/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-math-multiplication-range-500-math-multiply-0017-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-complex-0006-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/maze/complex/0006/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-complex-0006-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-complex-0007-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/maze/complex/0007/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-complex-0007-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-complex-0008-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/maze/complex/0008/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-complex-0008-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-complex-0009-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/maze/complex/0009/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-complex-0009-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-complex-0010-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/maze/complex/0010/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-complex-0010-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-moderate-0002-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/maze/moderate/0002/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-moderate-0002-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-moderate-0003-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/maze/moderate/0003/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-moderate-0003-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-moderate-0004-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/maze/moderate/0004/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-moderate-0004-index-mdx" */),
  "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-moderate-0005-index-mdx": () => import("./../../../src/templates/mind-zone-default-page.js?__contentFilePath=E:/www/vercodi/learnigma-build/content/mind/mind-zone/maze/moderate/0005/index.mdx" /* webpackChunkName: "component---src-templates-mind-zone-default-page-js-content-file-path-content-mind-mind-zone-maze-moderate-0005-index-mdx" */),
  "component---src-templates-mind-zone-listing-js": () => import("./../../../src/templates/mind-zone-listing.js" /* webpackChunkName: "component---src-templates-mind-zone-listing-js" */)
}

